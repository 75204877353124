import type { SearchParams } from '@/core/utils/url';
import type { FilterGroupV1 } from '@/features/filter/filter-data-v1-type';

import { dataLayerPush } from '@/core/features/tracking/google-data-layer';
import { getUrlSearchParams, searchParamsToArray, urlSearchParamsToObject } from '@/core/utils/url';

export const trackMultipleFilterChanges = (
    filterName: string,
    clientUrl: string,
    updatedSearchParams: SearchParams,
) => {
    const currentSearchParams = urlSearchParamsToObject(getUrlSearchParams(clientUrl));
    const currentlyActiveFilters = searchParamsToArray(filterName, currentSearchParams);
    const nextActiveFilters = searchParamsToArray(filterName, updatedSearchParams);

    currentlyActiveFilters.forEach((filterValue) => {
        if (nextActiveFilters.includes(filterValue)) {
            return;
        }
        trackFilterChange({ filterName, filterValue, isSelected: true });
    });

    nextActiveFilters.forEach((filterValue) => {
        if (currentlyActiveFilters.includes(filterValue)) {
            return;
        }
        trackFilterChange({ filterName, filterValue, isSelected: false });
    });
};

export const trackRemovedFilters = (filterGroups: FilterGroupV1[], clientUrl: string) => {
    const activeFilters = Object.entries(urlSearchParamsToObject(getUrlSearchParams(clientUrl)));
    activeFilters.forEach(([filterName, filterValues]) => {
        if (!filterGroups.some((group) => group.name === filterName)) {
            return;
        }
        (filterValues?.split(',') ?? []).forEach((filterValue) => {
            trackFilterChange({ filterName, filterValue, isSelected: true });
        });
    });
};

export const trackFilterChange = (data: { filterName: string; filterValue: string; isSelected: boolean }) => {
    const { filterName, filterValue, isSelected } = data;
    dataLayerPush({
        element: getFilterElementTrackingName(filterName, isSelected),
        event: 'click',
        filter: filterName,
        filterValue: filterValue,
    });
};

const getFilterElementTrackingName = (filterName: string, isSelected: boolean) => {
    if (filterName === 'activity' || filterName === 'activities') {
        return isSelected ? 'Aktivitaeten_Filter_Deselected' : 'Aktivitaeten_Filter_Selected';
    }

    return isSelected ? 'Filter_Deselected' : 'Filter_Selected';
};
