import type { FilterGroupV1 } from '@/features/filter/filter-data-v1-type';

import { filterUrlParams, getUrlSearchParams, mergeQueryParams, urlSearchParamsToObject } from '@/core/utils/url';
import { isFilterSort } from '@/features/filter/filter-data/service/filter-data-service';

const getFilterParamChanges = (
    clientUrl: string,
    filterGroups: FilterGroupV1[],
    groupName?: string,
    type?: 'deselect' | 'reset' | 'select',
): { addedUrlParams: URLSearchParams; removedUrlParams: string[] } => {
    const currentParams = getUrlSearchParams(clientUrl);
    const addedUrlParams = new URLSearchParams();
    const removedUrlParams: string[] = [];

    // Since we can have the same filter (with different options) multiple times in different groups we have to always
    // merge the selected options for each filter
    filterGroups.forEach((filterGroup) => {
        if (
            // undefined means we want to update all filters (e.g. reset all filters)
            groupName !== undefined &&
            filterGroup.name !== groupName &&
            !filterGroup.groups.some((group) => group.name === groupName)
        ) {
            return;
        }

        if (filterGroup.groups && filterGroup.groups.length > 0) {
            // Nested groups
            const { addedUrlParams: nestedAddedUrlParams, removedUrlParams: nestedRemovedUrlParams } =
                getFilterParamChanges(clientUrl, filterGroup.groups, groupName, type);

            removedUrlParams.push(...nestedRemovedUrlParams);
            nestedAddedUrlParams.forEach((value, key) => {
                const currentSelectedOptions = addedUrlParams.get(key)?.split(',') ?? [];
                const mergedSelectedOptions = [...currentSelectedOptions, ...value.split(',')];
                addedUrlParams.set(key, mergedSelectedOptions.join(','));
            });
            return;
        }

        const isDefaultSelected = hasDefaultSelected(filterGroup);
        const selectedOptions = getSelectedNonDefaultFilterOptionNamesInGroup(filterGroup);

        const isDefault = isDefaultSelected || selectedOptions.length === 0;
        const isSort = isFilterSort(filterGroup.displayType);

        if (type === 'reset' && isSort) {
            return;
        }

        if (isDefault) {
            removedUrlParams.push(filterGroup.name);
            return;
        }

        switch (type) {
            case 'select': {
                const mergedSelectedOptions = [
                    ...(addedUrlParams.get(filterGroup.name)?.split(',') ?? []),
                    ...selectedOptions,
                ];
                addedUrlParams.set(filterGroup.name, mergedSelectedOptions.join(','));
                break;
            }
            case 'deselect': {
                const existingValues = currentParams.get(filterGroup.name)?.split(',') || [];
                const updatedValues = existingValues.filter((val) => selectedOptions.includes(val));
                if (updatedValues.length > 0) {
                    const mergedSelectedOptions = [
                        ...(addedUrlParams.get(filterGroup.name)?.split(',') ?? []),
                        ...updatedValues,
                    ];
                    addedUrlParams.set(filterGroup.name, mergedSelectedOptions.join(','));
                } else {
                    removedUrlParams.push(filterGroup.name);
                }
                break;
            }
            case 'reset': {
                removedUrlParams.push(filterGroup.name);
                break;
            }
        }
    });

    return { addedUrlParams, removedUrlParams };
};

export const getUpdatedSearchParams = (
    clientUrl: string,
    filterGorups: FilterGroupV1[],
    groupName?: string,
    type?: 'deselect' | 'reset' | 'select',
) => {
    const currentParams = getUrlSearchParams(clientUrl);
    const { addedUrlParams, removedUrlParams } = getFilterParamChanges(clientUrl, filterGorups, groupName, type);

    // First remove filter that are marked for removal and aftet that add the addedUtlParams. That way we don't lose any selected options from other groups
    const cleanedUrlQuery = removedUrlParams ? filterUrlParams(currentParams, removedUrlParams) : currentParams;
    return urlSearchParamsToObject(mergeQueryParams(cleanedUrlQuery, addedUrlParams));
};

const getSelectedNonDefaultFilterOptionNamesInGroup = (filterGroup: FilterGroupV1): string[] => {
    return filterGroup.options.flatMap((option) => (option.isSelected && !option.isDefault ? option.name : []));
};

const hasDefaultSelected = (filterGroup: FilterGroupV1): boolean => {
    return !!filterGroup.options.find((option) => option.isSelected && option.isDefault);
};
